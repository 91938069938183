import React from "react";
import { graphql, Link } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";

import {
  headline1,
  headline2,
  headline3,
  headline4,
} from "../components/headlines";
import Layout from "../components/layout";
import ContactPerson from "../components/contactPerson";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import Seo from "../components/seo";

const shortcodes = {
  h1: headline1,
  h2: headline2,
  h3: headline3,
  h4: headline4,
};

export default function JobTemplate({ data: { mdx, jannik } }) {
  return (
    <Layout lang="en" langPath="nav.jobs.link">
      <Seo
        title={mdx.frontmatter.title}
        description={mdx.frontmatter.description}
        keywords={mdx.frontmatter.keywords}
      />
      <div className="parallax-translate bg-shape-cross" />
      <div className="container mt-4 mb-4 reveal">
        <div className="row">
          <div className="col-12">
            <h1 className="text-size-2 text-size-md-1">
              {mdx.frontmatter.headline}
            </h1>
          </div>
        </div>
      </div>
      <div className="container mt-4 mb-4 reveal">
        <div className="row">
          <div className="col-12">
            <MDXProvider components={shortcodes}>
              <MDXRenderer>{mdx.body}</MDXRenderer>
            </MDXProvider>
            <StaticImage
              src="../assets/images/kununu.png"
              alt="Kununu Top Company Award"
              width={364}
            />
          </div>
        </div>
      </div>
      <div className="container reveal">
        <div className="row">
          <div className="col-12 mb-6">
            <Link
              to="/en/jobs"
              className="btn text-color-white bg-monday-skyblue"
            >
              Go to overview
            </Link>
          </div>
        </div>
      </div>
      <div id="get-in-touch">
        <ContactPerson
          headline="We look forward to hearing from you."
          text="Got what it takes? Likeable and easy-going? Know how customers tick? Perfect. Then we need to talk. These are just the qualities we’re looking for in our team."
          contactEmail="jobs@monday-consulting.com"
          contactPhone="+49 40 22 86 842 0"
          contactName="Jannik Bischof, Lead Recruiter"
        >
          <GatsbyImage
            image={jannik.childImageSharp.gatsbyImageData}
            alt="Jannik Bischof"
          />
        </ContactPerson>
      </div>
    </Layout>
  );
}
export const pageQuery = graphql`
  query JobQueryEn($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        description
        keywords
        headline
      }
    }
    jannik: file(relativePath: { eq: "profiles/jannik-bischof.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
`;
